import api from 'api'
import t from './actionTypes'

import gatewayClient from 'src/gateway-client'
import { ERROR, pushNotification, SUCCESS } from 'src/redux/alerts/actions'
import { setCatalogBuilderMenuSlug } from 'src/redux/catalog-builder/actions'
import { clearGroups, setGroups } from 'src/redux/groups/actions'
import { receiveInventory, requestInventory } from 'src/redux/loading/actions'
import { clearMenus, setMenu } from 'src/redux/menus/actions'
import { clearProducts, setProducts } from 'src/redux/products/actions'

/**
 * This is only used in Depot Inventory (Depot Case).
*/
export function getDepotInventory (id, limit, offset, query) {
  return async (dispatch) => {
    return dispatch(fetchDepotCase(id, limit, offset, query))
  }
}

/**
 * Get Depot Inventory for Depot Case page. Makes request to MC-Gateway for a pared down response payload.
*/
export function fetchDepotCase (depotId, limit, offset = 0, query) {
  return async (dispatch) => {
    const isCSV = limit === -1
    if (!isCSV) dispatch({ type: t.REQUEST_DEPOT_INVENTORY })

    try {
      const { err, data } = await gatewayClient().getDepotInventory({ depotId, limit, offset, query })
      if (err) {
        dispatch(pushNotification(`Error getting depot inventory for ${depotId}`, ERROR))
        return
      }

      const { inventory } = data
      if (!isCSV) dispatch({ type: t.RECEIVE_DEPOT_INVENTORY })
      return inventory
    } catch (error) {
      dispatch(pushNotification('Unexpected error occurred', ERROR))
    }
  }
}

export function saveBatchChanges (depotId, quantities) {
  return async (dispatch) => {
    try {
      const { data } = await gatewayClient().patchDepotInventory({
        depotId,
        array: quantities
      })
      dispatch(
        pushNotification(
          `Successfully updated ${quantities.length} product(s) 🎉`,
          SUCCESS
        )
      )
      const { inventory } = data
      return inventory
    } catch (err) {
      return dispatch(pushNotification('Error saving changes', ERROR))
    }
  }
}

/* ---- CATALOG BUILDER THINGS ---- */

// FIXME: If we need to make this transformation we should make the gateway do it.
function transformDepotInventoryToMenuData (data) {
  const groupsMap = {}
  const productsMap = {}
  const groupIds = []

  data.forEach((product) => {
    const groupId = product.type.id
    if (!groupsMap[groupId]) {
      groupsMap[groupId] = product.type
      groupsMap[groupId].products = []
      groupIds.push(groupId)
    }
    groupsMap[groupId].products.push(product.catalogItemId)

    productsMap[product.catalogItemId] = product
  })

  return {
    groupsMap,
    productsMap,
    groupIds
  }
}

/**
 * Get Depot Inventory for Catalog Builder functionality.
*/

// FIXME: this should belong in catalog-builder/actions but because it's sharing the "RECEIVE_DEPOT_INVENTORY" action type i'm leaving it here.
export function fetchDepotInventory (depotId, caseBuilder = false) {
  return async (dispatch) => {
    dispatch(requestInventory())

    try {
      const { err, data } = await api.getDepotInventory({ depotId })
      if (err) {
        dispatch(pushNotification(`Error getting depot inventory for ${depotId}`, ERROR))
        return
      }

      dispatch(receiveInventory())

      if (caseBuilder) {
        // Clear existing data
        dispatch(clearMenus())
        dispatch(clearGroups())
        dispatch(clearProducts())

        // Transform data for catalog builder
        const { groupIds, groupsMap, productsMap } = transformDepotInventoryToMenuData(data)

        // Update state with transformed data
        dispatch(setCatalogBuilderMenuSlug('default'))
        dispatch(setMenu({ groups: groupIds }, 'default'))
        dispatch(setGroups(groupsMap))
        dispatch(setProducts(productsMap))
      } else {
        // Dispatch inventory data for non-case builder
        dispatch({
          type: t.RECEIVE_DEPOT_INVENTORY,
          payload: { data }
        })
      }
    } catch (error) {
      dispatch(pushNotification('Unexpected error occurred', ERROR))
    }
  }
}
