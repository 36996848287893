import styled from '@emotion/styled'
import { func, number, oneOfType, string } from 'prop-types'
import 'rc-slider/assets/index.css'
import React from 'react'

import Slider from 'rc-slider'
import { Column, Flex, Row } from 'src/microcomponents/flexbox-helpers'
import InputLabel from 'src/microcomponents/input-label'

const EffectSlider = ({
  inputLabel,
  invalidNameClass,
  itemReference,
  setEffectName,
  sliderName,
  sliderValue,
  updateSliderValue
}) => (
  <Row align="center" top={2} full>
    <Flex componentStyle={{ width: '30%' }}>
      <Column>
        <InputLabel content={inputLabel} />
        <input
          className={invalidNameClass}
          onChange={(event) => setEffectName(event, itemReference)}
          value={sliderName}
        />
      </Column>
    </Flex>
    <SliderContainerContainer>
      <SliderContainer>
        <Slider
          min={0}
          max={100}
          onChange={(value) => updateSliderValue(value, itemReference)}
          value={Number(sliderValue)}
        />
      </SliderContainer>
    </SliderContainerContainer>
    <SliderValue>{sliderValue}</SliderValue>
  </Row>
)

export default EffectSlider

EffectSlider.defaultProps = {
  sliderValue: 0,
  sliderName: '',
  inputLabel: ''
}

EffectSlider.propTypes = {
  inputLabel: string,
  invalidNameClass: string,
  itemReference: number,
  setEffectName: func,
  sliderName: string,
  sliderValue: oneOfType([number, string]),
  updateSliderValue: func
}

const SliderValue = styled.div`
  width: 10%;
`

const SliderContainerContainer = styled.div`
  width: 60%;
`

const SliderContainer = styled.div`
  margin: auto;
  width: 90%;
`
